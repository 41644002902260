/**
 * This file represents all the strapi filters that are used to fetch data from the backend
 * Make sure to document the exact entity the filters represent
 * You can also create your custom populate and pagination if needed
 */

import { PaginationByOffset, PaginationByPage } from '@/lib/types/strapi';

/**
 * Strapi populate option
 * You can create your custom populate filters here
 */

export const populateAll = '*';

export const populateAllProduct = {
  images: { fields: ['url'] },
  categories: { fields: ['title', 'categoryMdvAttribute', 'urlKey', 'urlPath'] },
  watabiContent: {
    populate: {
      location: { fields: ['*'] },
      strongPoint: { fields: ['*'] },
      company: { fields: ['*'] },
      reference: { fields: ['*'] },
      sequence: {
        fields: ['*'],
        populate: {
          location_sequence: { fields: ['*'] },
          day_title: { fields: ['*'] },
          block: { fields: ['*'] }
        }
      }
    }
  }
};

export const populateJourneyCountriesAndGeographicZones = {
  images: { fields: ['url'] },
  categories: { fields: ['title', 'categoryMdvAttribute', 'urlKey', 'urlPath'] },
  watabiContent: { populate: { location: { fields: ['*'] } } }
};

export const populateProductCategories = { categories: { fields: ['*'] } };

export const populateWpPage = 'deep,5';
/**
 * Strapi pagination option
 * You can create your custom pagination
 */

export const defaultPagination: PaginationByOffset = {
  start: 0,
  limit: 8,
  withCount: true
};

export const limitPagination = (start: number, limit: number): PaginationByOffset => ({
  start,
  limit,
  withCount: true
});

export const pagePagination = (page: number, pageSize: number): PaginationByPage => ({
  page,
  pageSize,
  withCount: true
});

export const findJourneysByGeographicZoneSlug = (geographicZoneSlug: string): object => ({
  categories: { categoryMdvAttribute: 'geographic-zone', urlKey: geographicZoneSlug }
});

export const findProductsByCategoryFilters = (category: string): object => ({
  categories: {
    title: {
      $contains: category
    }
  }
});
